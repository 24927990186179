import './index.scss'
// import '../../assets/scripts/myNiceScroll'
// import {resizeScroll} from '../../assets/scripts/myNiceScroll'
import '@fancyapps/fancybox/dist/jquery.fancybox.min'
import Swiper from 'swiper/dist/js/swiper.min.js'

var indexswiper = new Swiper(".index-banner .mySwiper", {
  speed: 1000,
  loop: true,
  autoplay: {
    delay: 15000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
    waitForTransition: false,
  },
  // breakpoints: {
  //   1024: { 
  //     autoplay: {
  //       delay: 6000,
  //       stopOnLastSlide: false,
  //       disableOnInteraction: false,
  //       waitForTransition: false,
  //     },
  //   }
  // },
  pagination: {
    el: ".index-banner .swiper-pagination",
    clickable: true
  },
  navigation: {
    nextEl: ".index-banner .swiper-button-next",
    prevEl: ".index-banner .swiper-button-prev",
  },
  on: {
    init: function(swiper){
      $('.index-banner .swiper-mypagination .totalNum').html(this.slides.length - 2)
    },
    slideChangeTransitionStart: function(){
      $('.index-banner .swiper-mypagination .activeNum').html(this.realIndex + 1)
    },
  },
});

var solutionswipertext = new Swiper(".section2 .right .mySwiper", {
  allowTouchMove: false,
  speed: 800,
  loop: true,
  spaceBetween : 20,
  pagination: {
    el: ".section2 .left .swiper-pagination",
    clickable: true
  },
  breakpoints: { 
    992: {
      allowTouchMove: true,
    },
  }
});
var solutionswiperimg = new Swiper(".section2 .left .mySwiper", {
  speed: 800,
  loop: true,
  autoplay: {
    delay: 4000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
    waitForTransition: false,
  },
  pagination: {
    el: ".section2 .left .swiper-pagination",
    clickable: true
  },
  controller: {
    control: solutionswipertext, //控制Swiper1
  },
  // on: {
  //   slideChangeTransitionStart: function(){
  //     solutionswipertext.slideTo(this.activeIndex)
  //   },
  // },
});
solutionswipertext.controller.control = solutionswiperimg;//Swiper1控制Swiper2，需要在Swiper2初始化后
solutionswiperimg.controller.control = solutionswipertext;//Swiper2控制Swiper1，需要在Swiper1初始化后

var proswiperimg = new Swiper(".section3 .right .mySwiper", {
  speed: 800,
  loop: true,
  autoplay: {
    delay: 4000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
    waitForTransition: false,
  },
  pagination: {
    el: ".section3 .right .swiper-pagination",
    clickable: true
  },
});


var newswiperimg = new Swiper(".section4 .left .mySwiper", {
  speed: 800,
  loop: true,
  autoplay: {
    delay: 4000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
    waitForTransition: false,
  },
  pagination: {
    el: ".section4 .left .swiper-pagination",
    clickable: true
  },
});

$(document).scroll(function () {
  let scroH = $(document).scrollTop(); //滚动高度
  let viewH = $(window).height(); //可见高度
  let contentH = $(document).height(); //内容高度

  if (scroH > 10) {
    $('.section1 .index-banner').addClass('scroll')
  }else{
    $('.section1 .index-banner').removeClass('scroll')
  }
  if (contentH - (scroH + viewH) <= 100) { 
    //距离底部高度小于100px
  }
  if (contentH == (scroH + viewH)) { 
    //滚动条滑到底部啦
  }
});

$(document).ready(function(){
  // 视频相关
  var $video = $('.index-banner video')
  $video.each(function(index){
    this.addEventListener('play', function () { //播放开始执行的函数
      $(this).closest('.video').find('.video-play').hide()
    });
    this.addEventListener('pause', function () { //暂停开始执行的函数
      $(this).closest('.video').find('.video-play').show()
    });
  })
  $('.index-banner .video .video-play').on('click',function(){
    let $parent = $(this).closest('.video')
    $parent.find('video')[0].play()
    $(this).hide()
  })
})